<template>
  <div>
    <!--begin::Content header-->
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Connexion</h3>
        <p class="text-muted font-weight-semi-bold">
          Entrez votre nom d'utilisateur et le mot de passe.
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" class="alert alert-info">
          <div class="alert-text">
            Utiliser vos identifiants (<strong>admin@demo.com</strong>) et mot
            de passe (<strong>demo</strong>) pour se connecter.
          </div>
        </div>

        <div
          v-if="errors"
          role="alert"
          :class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text">
            {{ errors }}
          </div>
        </div>

        <div role="alert" class="alert alert-danger" v-if="connectionStatus">
          <div class="alert-text">
            {{ connectionStatus }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label="Adresse E-mail"
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-3 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.username.$model"
            :state="validateState('username')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email est requis et doit être une adresse e-mail valide.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label="Mot de passe"
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-3 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Le mot de passe est requis.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            href="#"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Mot de passe oublié ?
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-3 my-3 font-size-3"
          >
            Connexion
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      errors: [],
      success: false,
      connectionStatus: ""
    };
  },
  beforeCreate() {
    document.title = "TOGOHOME | Authentification";
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required,
        minLength: minLength(4)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    resetForm() {
      this.form = {
        username: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );

      // dummy delay

      if (!navigator.onLine) {
        this.connectionStatus =
          "Impossible de se connecter à internet. Verifiez votre réseau.";
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right",
          "disabled"
        );
        return;
      }
      // send login request
      this.$store
        .dispatch("LOGIN", this.form)
        .then(() => {
          this.success = true;
          this.$router.replace({ name: "dashboard" });
        })
        .catch(error => {
          this.errors = error.response.data.message;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
